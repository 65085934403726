exports.AUTH_URL = 'https://api.rifaly.co.tz/api/users/auth';
exports.PROFILE_URL = 'https://api.rifaly.co.tz/api/users/profile';
exports.PUBLISHER_URL = 'https://api.rifaly.co.tz/api/publishers/';
exports.BILLING_URL = 'https://billing.rifaly.co.tz/billing/';
exports.CORPORATES_URL = 'https://api.rifaly.co.tz/api/corporates/';
exports.USERS_URL = 'https://api.rifaly.co.tz/api/users/';
exports.COUNTRIES_URL = 'https://billing.rifaly.co.tz/billing/countries/';
exports.AUDIO_URL = 'https://api.rifaly.co.tz/api/audio/api/v1/';

// exports.AUTH_URL = 'http://localhost:5000/api/users/auth';
// exports.PUBLISHER_URL = 'http://localhost:5001/api/publishers/';
// exports.BILLING_URL = 'https://billing.sandbox.rifaly.com/billing/';
// exports.USERS_URL = 'http://localhost:5000/api/users/';
// exports.COUNTRIES_URL = 'https://billing.sandbox.rifaly.com/billing/countries/';

// exports.AUTH_URL = 'https://api.sandbox.rifaly.com/api/users/auth';
// exports.PUBLISHER_URL = 'https://api.sandbox.rifaly.com/api/publishers/';
// exports.BILLING_URL = 'https://billing.sandbox.rifaly.com/billing/';
// exports.USERS_URL = 'https://api.sandbox.rifaly.com/api/users/';
// exports.COUNTRIES_URL = 'https://billing.sandbox.rifaly.com/billing/countries/';
// exports.CORPORATES_URL = 'https://api.sandbox.rifaly.com/api/corporates/';
// exports.AUDIO_URL = 'https://api.sandbox.rifaly.com/api/audio/api/v1/';


exports.STORAGE_URL = 'https://mpaper.s3.us-west-2.amazonaws.com/';