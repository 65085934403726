import { fetchGenresApi } from "../../api/publisher";

export const FETCH_GENRES_LOADING = "FETCH_GENRES_LOADING";
export const FETCH_GENRES_SUCCESS = "FETCH_GENRES_SUCCESS";

export const fetchGenres = () => {
  return (dispatch) => {
    dispatch(fetchGenresLoading());
    return fetchGenresApi()
      .then((res) => {
        const data = (res.data.data) || [];
        dispatch(fetchGenresSuccess(data));
      })
      .catch((error) => {
        console.log(error);
      });
  };
};

//  staff 

const fetchGenresLoading = () => {
  return {
    type: FETCH_GENRES_LOADING,
  };
};

const fetchGenresSuccess = (data) => {
  return {
    type: FETCH_GENRES_SUCCESS,
    payload: data,
  };
};

