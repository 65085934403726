import React, {useEffect, useState} from 'react';
import './style.css';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  BarElement,
  Title,
  LineElement,
  ArcElement,
  Tooltip,
  Legend,
} from 'chart.js';
import {Doughnut, Line} from 'react-chartjs-2';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  BarElement,
  Title,
  LineElement,
  ArcElement,
  Tooltip,
  Legend,
);

function PublisherSummary(props) {
  console.log(
    '🚀 ~ file: PublisherSummary.js:30 ~ PublisherSummary ~ props:',
    props,
  );

  const thousandSeparator = (x) => {
    return x ? x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') : 'n/a';
  };
  var company = JSON.parse(localStorage.getItem('RIFALYCOMPANY'));
  const user = JSON.parse(localStorage.getItem('RIFALYPODCASTER'));
  const [ read, setRead ] = useState(0);
  const [ currency, setCurrency ] = useState('TZS');
  const [ platform, setPlatform ] = useState(props.platform);
  const [ pieTitle, setPieTitle ] = useState('Reads by Platform');
  const [ pieData, setPieData ] = useState({labels: [], datasets: []});
  const [ dataLine, setDataLine ] = useState({labels: [], datasets: []});
  const months = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ];

  const options = {
    plugins: {
      legend: {
        display: true,
        position: 'bottom',
        labels: {
          color: '#BCBCBC',
          font: {
            size: 14,
          },
          boxWidth: 8,
          boxHeight: 8,
          usePointStyle: true,
        },
      },
    },
  };

  const optionsLine = {
    responsive: true,
    plugins: {
      legend: {
        position: 'bottom',
        align: 'end',
        labels: {
          color: '#BCBCBC',
          font: {
            size: 13,
          },
          boxWidth: 8,
          boxHeight: 8,
          usePointStyle: true,
        },
      },
      title: {
        display: false,
      },
    },
    elements: {
      bar: {
        borderRadius: 10,
      },
    },
    scales: {
      x: {
        title: {
          display: true,
          text: 'Date',
          padding: {top: 20, left: 0, right: 0, bottom: 0},
        },
        grid: {
          display: false,
          borderColor: 'transparent',
        },
      },
      y: {
        title: {
          display: true,
          text: 'Counts',
        },
        grid: {
          offset: false,
          borderDash: [ 1, 3 ],
          color: '#E0E6ED',
          borderColor: 'transparent',
          tickColor: 'transparent',
          categoryPercentage: 0.4,
        },
      },
    },
  };

  useEffect(() => {
    var labels = [];
    var date = new Date();
    let currentMonth = date.getMonth();
    let currentYear = date.getFullYear();
    let today = date.getDate();
    let day = today + 1;
    for (let i = 0; i < 30; i++) {
      day--;
      if (parseInt(day) <= 0) {
        currentMonth = currentMonth - 1;
        if (currentMonth < 0) currentMonth = 11;
        day = parseInt(
          new Date(currentYear, parseInt(currentMonth) + 1, 0).getDate(),
        );
      }
      labels[ i ] = months[ currentMonth ] + ' ' + day;
    }
    labels = labels.reverse();
    var impressions = [];
    var downs = [];
    var streams = [];
    if (props.graphData) {
      labels.forEach((label, index) => {
        if (props.graphData.impressions) {
          let imp = props.graphData.impressions
            .map((i) => {
              let x = i;
              let d = new Date(i.date_created);
              x.date = `${months[ parseInt(d.getMonth()) ]} ${parseInt(d.getDate()) + 1
                }`;
              return x;
            })
            .find((ip) => ip.date == label);
          let val = imp ? imp.impressions : 0;
          impressions.push(val);
        }

        if (props.graphData.downloads) {
          let down = props.graphData.downloads
            .map((i) => {
              let x = i;
              let d = new Date(i.date_created);
              x.date = `${months[ parseInt(d.getMonth()) ]} ${parseInt(d.getDate()) + 1
                }`;
              return x;
            })
            .find((ip) => ip.date == label);
          let val2 = down ? down.downloads : 0;
          downs.push(val2);
        }
        if (props.graphData.streams) {
          let st = props.graphData.streams
            .map((i) => {
              let x = i;
              let d = new Date(i.date_created);
              x.date = `${months[ parseInt(d.getMonth()) ]} ${parseInt(d.getDate()) + 1
                }`;
              return x;
            })
            .find((ip) => ip.date == label);
          let val3 = st ? st.streams : 0;
          streams.push(val3);
        }
      });
    }

    var pie = {
      Android: 0, iOS: 0, Web: 0, Other: 0
    };

    if (
      props.data && props.data.summary && props.data.summary.platform
    ) {
      const pieDownloads = props.data.summary.platform;
      pie = {
        Android: pieDownloads.streams ? pieDownloads.streams.android : 0,
        iOS: pieDownloads.streams ? pieDownloads.streams.ios : 0,
        Web: pieDownloads.streams ? pieDownloads.streams.web : 0,
        Other: pieDownloads.streams ? pieDownloads.streams.other : 0,
      };

      if (pieDownloads.downloads == 0 && pieDownloads.streams == 0) {
        setPieTitle('Impressions by Platform');
        pie = {
          Android: pieDownloads.impressions ? pieDownloads.impressions.android : 0,
          iOS: pieDownloads.impressions ? pieDownloads.impressions.ios : 0,
          Web: pieDownloads.impressions ? pieDownloads.impressions.web : 0,
          Other: pieDownloads.impressions ? pieDownloads.impressions.other : 0,
        };
      }
    }

    const pd = {
      labels: Object.keys(pie),
      datasets: [
        {
          label: 'Impressions',
          data: Object.values(pie),
          backgroundColor: [ '#2BC38C', '#2333b9', '#c66691', '#E7E7E7' ],
          radius: 120,
          weight: 1,
          cutout: 120,
          borderWidth: 1,
          hoverOffset: 7,
        },
      ],
    };

    const lineData = {
      labels,
      padding: 10,
      datasets: [
        {
          label: 'Impressions',
          data: impressions,
          borderColor: '#2BC38C',
          backgroundColor: '#2BC38C',
          tension: 0.4,
          barThickness: 10,
          borderRadius: 10,
          barPercentage: 1.0,
          categoryPercentage: 1.0,
          offset: false,
        },
        {
          label: 'Downloads',
          data: downs,
          borderColor: '#E7E7E7',
          backgroundColor: '#E7E7E7',
          tension: 0.4,
          barThickness: 10,
          borderRadius: 10,
          barPercentage: 1.0,
          categoryPercentage: 0.4,
          offset: false,
        },
        {
          label: 'Streams',
          data: streams,
          borderColor: '#2B3422',
          backgroundColor: '#2B3422',
          tension: 0.4,
          barThickness: 10,
          borderRadius: 10,
          barPercentage: 1.0,
          categoryPercentage: 0.4,
          offset: false,
        },
      ],
    };
    setDataLine(lineData);
    setPieData(pd);
    setRead(1);

  }, [ read ]);
  return (
    <div className='row'>
      <div className='col-md-12 my-4'>
        <div className='row'>
          {user.role == 6 ? null : (
            <div className='col-12 col-lg-6 col-xl'>
              <div className='card'>
                <div className='card-body'>
                  <div className='row align-items-center gx-0'>
                    <div className='col'>
                      <h6 className='text-capitalize text-muted mb-3'>Sales</h6>
                      <span className='h2 mb-0 mr-4'>
                        {props.data.sales
                          ? props.data.sales.currency
                          : 'TZS'}
                        {props.data.sales && props.data.sales.amount > 0
                          ? thousandSeparator(
                            (
                              0.01 *
                              (company.commission) *
                              props.data.sales.amount
                            ).toFixed(2),
                          )
                          : 0}
                      </span>

                      <span className='badge bg-success-soft mr-2 mt-n1'></span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}

          <div className='col-12 col-lg-6 col-xl'>
            <div className='card'>
              <div className='card-body'>
                <div className='row align-items-center gx-0'>
                  <div className='col'>
                    <h6 className='text-capitalize text-muted mb-3'>
                      Impressions
                    </h6>
                    <span className='h2 mb-0'>
                      {props.data.summary.total ? thousandSeparator(props.data.summary.total.impressions) : 0}
                    </span>
                    <span className='badge bg-success-soft mr-2 mt-n1'>
                      {platform}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className='col-12 col-lg-6 col-xl'>
            <div className='card'>
              <div className='card-body'>
                <div className='row align-items-center gx-0'>
                  <div className='col'>
                    <h6 className='text-capitalize text-muted mb-3'>
                      Reads
                    </h6>

                    <span className='h2 mb-0'>
                      {props.data.summary.total ? thousandSeparator(props.data.summary.total.downloads) : 0}
                    </span>

                    <span className='badge bg-success-soft mr-2 mt-n1'></span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className='col-12 col-lg-6 col-xl'>
            <div className='card'>
              <div className='card-body'>
                <div className='row align-items-center gx-0'>
                  <div className='col'>
                    <h5 className='text-capitalize text-muted mb-3'>Streams</h5>

                    <span className='h2 mb-0'>
                      {props.data.summary.total ? props.data.summary.total.streams : 0}
                    </span>

                    <span className='badge bg-success-soft mr-2 mt-n1'></span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className='row'>
          <div className='col-12 col-xl-8'>
            <div className='card card-fill-sm'>
              <div className='card-header'>
                <h4 className='card-header-title'>Performance</h4>
              </div>
              <div className='card-body'>
                <Line
                  options={optionsLine}
                  data={dataLine}
                />
              </div>
            </div>
          </div>

          <div className='col-12 col-xl-4'>
            <div className='card card-fill-sm'>
              <div className='card-header'>
                <h4 className='card-header-title'>{pieTitle}</h4>
              </div>
              <div className='card-body relative'>
                <Doughnut
                  data={pieData}
                  options={options}
                />
              </div>
            </div>
          </div>
        </div>

        <div className='row'>
          <div className='col-md-12'>
            <div className='card'>
              {/* <Line data={dataLine} options={optionsLine} /> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PublisherSummary;
