import React from 'react';
import {NavLink} from 'react-router-dom';

export default function Navs() {
  let pid = JSON.parse(localStorage.getItem("RIFALYPODCASTER")).companyId;

  return (
    <ul className="nav nav-tabs">

      {/* <li className="nav-item">
        <NavLink to="/reports/revenue" className="nav-link">Revenue</NavLink>
      </li> */}
      <li className="nav-item">
        <NavLink to="/reports/streams" className="nav-link">Streams</NavLink>
      </li> 
    </ul>
  )
}
